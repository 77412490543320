<template>
  <!-- Topbar Start -->
  <div class="container-fluid bg-success px-5 d-none d-lg-block">
    <div class="row gx-0">
      <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <!-- <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-twitter fw-normal"></i
          ></a> -->
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href="https://www.facebook.com/profile.php?id=61560295844986"
            ><i class="fab fa-facebook-f fw-normal"></i
          ></a>
          <!-- <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href=""
            ><i class="fab fa-linkedin-in fw-normal"></i
          ></a> -->
          <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
            href="https://www.instagram.com/visitgorillarwanda?igsh=bzhzZnpwZmMyczc="
            ><i class="fab fa-instagram fw-normal"></i
          ></a>
          <!-- <a
            class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
            href=""
            ><i class="fab fa-youtube fw-normal"></i
          ></a> -->
        </div>
      </div>
      <!-- <div class="col-lg-4 text-center text-lg-end">
        <div class="d-inline-flex align-items-center" style="height: 45px">
          <a href="#"
            ><small class="me-3 text-light"
              ><i class="fa fa-user me-2"></i>Register</small
            ></a
          >
          <a href="#"
            ><small class="me-3 text-light"
              ><i class="fa fa-sign-in-alt me-2"></i>Login</small
            ></a
          >
          <div class="dropdown">
            <a
              href="#"
              class="dropdown-toggle text-light"
              data-bs-toggle="dropdown"
              ><small><i class="fa fa-home me-2"></i> My Dashboard</small></a
            >
            <div class="dropdown-menu rounded">
              <a href="#" class="dropdown-item"
                ><i class="fas fa-user-alt me-2"></i> My Profile</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-comment-alt me-2"></i> Inbox</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-bell me-2"></i> Notifications</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-cog me-2"></i> Account Settings</a
              >
              <a href="#" class="dropdown-item"
                ><i class="fas fa-power-off me-2"></i> Log Out</a
              >
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar & Hero Start -->
  <div class="container-fluid position-relative p-0">
    <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
      <a href="/" class="navbar-brand p-0">
        <h1 class="m-0">
          <i class="fa fa-map-marker-alt me-3"></i>Visit Gorilla Rwanda
        </h1>
        <!-- <img src="img/logo.png" alt="Logo"> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="fa fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto py-0">
          <a href="/" class="nav-item nav-link active">Home</a>
          <a href="/about" class="nav-item nav-link">About</a>
          <a href="/services" class="nav-item nav-link">Services</a>
          <a href="/destination" class="nav-item nav-link">Destination</a>
          <div class="nav-item dropdown">
            <a
              href="/packages"
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              >Packages</a
            >
            <div class="dropdown-menu m-0">
              <a href="/packages" class="dropdown-item">General Packages</a>
              <a href="/kigali-tours" class="dropdown-item">Kigali Tours</a>
              <!-- <a href="/country-tours" class="dropdown-item">Country Tours</a>
              <a href="/akagera-tours" class="dropdown-item">Akagera Tour</a>
              <a href="/gorilla-tour" class="dropdown-item">Gorilla Tour</a> -->
            </div>
          </div>
          <a href="/blog" class="nav-item nav-link">Blog</a>
          <a href="/contact" class="nav-item nav-link">Contact</a>
        </div>
        <a
          href="/booking"
          class="btn btn-success rounded-pill py-2 px-4 ms-lg-4"
          >Book Now</a
        >
      </div>
    </nav>

    <!-- Carousel Start -->
    <div class="carousel-header">
      <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
          <li
            data-bs-target="#carouselId"
            data-bs-slide-to="0"
            class="active"
          ></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active">
            <img
              src="img/gorilla.jpeg"
              class="img-fluid"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3" style="max-width: 900px">
                <h4
                  class="text-white text-uppercase fw-bold mb-4"
                  style="letter-spacing: 3px"
                >
                  Visit Gorilla Rwanda
                </h4>
                <h1 class="display-2 text-capitalize text-white mb-4">
                  Let's Visit Rwanda Together!
                </h1>
                <p class="mb-5 fs-5">Where your next adventure awaits,</p>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn-hover-bg btn btn-success rounded-pill text-white py-3 px-5"
                    href="/packages"
                    >Discover Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
              class="img-fluid"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3" style="max-width: 900px">
                <h4
                  class="text-white text-uppercase fw-bold mb-4"
                  style="letter-spacing: 3px"
                >
                  Visit Gorilla Rwanda
                </h4>
                <h1 class="display-2 text-capitalize text-white mb-4">
                  You Like To Go?
                </h1>
                <p class="mb-5 fs-5">Unforgettable journeys begin here,</p>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn-hover-bg btn btn-success rounded-pill text-white py-3 px-5"
                    href="/packages"
                    >Discover Now</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="img/birds-at-umusambi-village.jpeg"
              class="img-fluid"
              alt="Image"
            />
            <div class="carousel-caption">
              <div class="p-3" style="max-width: 900px">
                <h4
                  class="text-white text-uppercase fw-bold mb-4"
                  style="letter-spacing: 3px"
                >
                  Visit Gorilla Rwanda
                </h4>
                <h1 class="display-2 text-capitalize text-white mb-4">
                  You Like To Go?
                </h1>
                <p class="mb-5 fs-5">Guiding you to timeless experiences.,</p>
                <div class="d-flex align-items-center justify-content-center">
                  <a
                    class="btn-hover-bg btn btn-success rounded-pill text-white py-3 px-5"
                    href="/packages"
                    >Discover Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev"
        >
          <span
            class="carousel-control-prev-icon btn bg-success"
            aria-hidden="false"
          ></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next"
        >
          <span
            class="carousel-control-next-icon btn bg-success"
            aria-hidden="false"
          ></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->
  </div>
  <div
    class="container-fluid search-bar position-relative"
    style="top: -50%; transform: translateY(-50%)"
  >
    <div class="container">
      <div
        class="position-relative rounded-pill w-100 mx-auto p-5"
        style="background: rgba(19, 53, 123, 0.8)"
      >
        <input
          class="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
          type="text"
          placeholder="Eg: Gorilla Volcanoes National Park"
        />
        <button
          type="button"
          class="btn btn-success rounded-pill py-2 px-4 position-absolute me-2"
          style="top: 50%; right: 46px; transform: translateY(-50%)"
        >
          Search
        </button>
      </div>
    </div>
  </div>
  <!-- Navbar & Hero End -->

  <!-- About Start -->
  <div class="container-fluid about py-5">
    <div class="container py-5">
      <div class="row g-5 align-items-center">
        <div class="col-lg-5">
          <div
            class="h-100"
            style="
              border: 50px solid;
              border-color: transparent #13357b transparent #13357b;
            "
          >
            <img
              src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
              class="img-fluid w-100 h-100"
              alt=""
            />
          </div>
        </div>
        <div
          class="col-lg-7"
          style="
            background: linear-gradient(
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8)
              ),
              url(img/about-img-1.png);
          "
        >
          <h5 class="section-about-title pe-3">About Us</h5>
          <h1 class="mb-4">
            Welcome to <span class="text-success">Visit Gorilla Rwanda</span>
          </h1>
          <p class="mb-4">
            we are passionate about crafting unforgettable travel experiences
            for discerning travelers, we curate itineraries that go beyond the
            ordinary, allowing you to truly connect with the destinations you
            visit.
          </p>
          <p class="mb-4">
            Our experienced guides are not just storytellers, but cultural
            ambassadors. They'll share their insights and ensure you have a safe
            and memorable journey.
          </p>
          <!-- <div class="row gy-2 gx-4 mb-4">
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>First Class
                Flights
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>Handpicked
                Hotels
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>5 Star
                Accommodations
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>Latest Model
                Vehicles
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>150 Premium
                City Tours
              </p>
            </div>
            <div class="col-sm-6">
              <p class="mb-0">
                <i class="fa fa-arrow-right text-success me-2"></i>24/7 Service
              </p>
            </div>
          </div> -->
          <!-- <a class="btn btn-success rounded-pill py-3 px-5 mt-2" href=""
            >Read More</a
          > -->
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->

  <!-- Services Start -->
  <div class="container-fluid bg-light service py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Searvices</h5>
        <h1 class="mb-0">Our Services</h1>
      </div>
      <div class="row g-4">
        <div class="col-lg-6">
          <div class="row g-4">
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Themed Tours</h5>
                  <p class="mb-0">
                    Explore destinations through a specific lens, like cultural
                    immersion, historical exploration, culinary adventures, or
                    wildlife encounters.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-globe fa-4x text-success"></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Hotel Reservation</h5>
                  <p class="mb-0">
                    Book your room directly through us, ensuring a seamless
                    experience and allowing you to concentrate on creating
                    unforgettable memories in Rwanda.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-hotel fa-4x text-success"></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 pe-0"
              >
                <div class="service-content text-end">
                  <h5 class="mb-4">Small Group Tours</h5>
                  <p class="mb-0">
                    Experience a more intimate and personalized journey with a
                    limited number of participants.
                  </p>
                </div>
                <div class="service-icon p-4">
                  <i class="fa fa-user fa-4x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row g-4">
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-hotel fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Transportation</h5>
                  <p class="mb-0">
                    Enjoy comfortable and reliable transportation arrangements,
                    including private drivers, local trains, or scenic cruises
                    (depending on your itinerary).
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-user fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Travel Guides</h5>
                  <p class="mb-0">
                    We prioritize eco-friendly tourism initiatives that minimize
                    environmental impact.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                class="service-content-inner d-flex align-items-center bg-white border border-success rounded p-4 ps-0"
              >
                <div class="service-icon p-4">
                  <i class="fa fa-cog fa-4x text-success"></i>
                </div>
                <div class="service-content">
                  <h5 class="mb-4">Supporting Local Communities</h5>
                  <p class="mb-0">
                    We partner with local businesses and guides, contributing to
                    the economic well-being of the destinations you visit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="text-center">
            <!-- <a class="btn btn-success rounded-pill py-3 px-5 mt-2" href=""
              >Service More</a
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Services End -->

  <!-- Destination Start -->
  <div class="container-fluid destination py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Destination</h5>
        <h1 class="mb-0">Popular Destination</h1>
      </div>
      <div class="tab-class text-center">
        <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill active"
              data-bs-toggle="pill"
              href="#tab-1"
            >
              <span class="text-dark" style="width: 150px"
                >All Destination</span
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex py-2 mx-3 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-2"
            >
              <span class="text-dark" style="width: 150px"
                >Akagera Park</span
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-3"
            >
              <span class="text-dark" style="width: 150px"
                >Nyungwe Park</span
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-4"
            >
              <span class="text-dark" style="width: 150px">Lake Kivu</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-5"
            >
              <span class="text-dark" style="width: 150px"
                >Volcanoes National Park</span
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill"
              data-bs-toggle="pill"
              href="#tab-6"
            >
              <span class="text-dark" style="width: 150px">Kigali City</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="tab-1" class="tab-pane fade show p-0 active">
            <div class="row g-4">
              <div class="col-xl-8">
                <div class="row g-4">
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/rwanda1.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Beautiful Rwanda</h4>
                        <!-- <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a> -->
                      </div>
                      <div class="search-icon">
                        <a href="img/rwanda1.jpeg" data-lightbox="destination-1"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/birds-at-umusambi-village.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Lake Kivu Beach</h4>
                        <!-- <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a> -->
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/lake-kivu-shores.jpeg"
                          data-lightbox="destination-2"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/gorilla.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">
                          Kinigi Mountain Gorilla
                        </h4>
                        <!-- <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a> -->
                      </div>
                      <div class="search-icon">
                        <a href="img/kinigi.jpeg" data-lightbox="destination-7"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="destination-img">
                      <img
                        class="img-fluid rounded w-100"
                        src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                        alt=""
                      />
                      <div class="destination-overlay p-4">
                        <h4 class="text-white mb-2 mt-3">Mountain Gorilla</h4>
                        <!-- <a href="#" class="btn-hover text-white"
                          >View All Place <i class="fa fa-arrow-right ms-2"></i
                        ></a> -->
                      </div>
                      <div class="search-icon">
                        <a
                          href="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                          data-lightbox="destination-8"
                          ><i
                            class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="destination-img h-100">
                  <img
                    class="img-fluid rounded w-100 h-100"
                    src="img/muhazi-beach-hotel.jpeg"
                    style="object-fit: cover; min-height: 300px"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Muhazi Beach Hotel</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/muhazi-beach-hotel.jpeg"
                      data-lightbox="destination-4"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/rwanda-mountain.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Rwanda Mountain</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/rwanda-mountain.jpeg"
                      data-lightbox="destination-4"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/wildlife-in-akagera.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Akagera Parc</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/wildlife-in-akagera.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/muhabura.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Muhabura</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a href="img/muhabura.jpeg" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-2" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Akagera-Hippos.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Akagera</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Akagera-Hippos.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Parc_National_d'Akagera.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">National Park Akagera</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Parc_National_d'Akagera.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/murchison-falls-savanna-safari13-1024x683.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">National Park Akagera</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/murchison-falls-savanna-safari13-1024x683.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/akagera-national-park-zebras-rwanda.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">National Park Akagera</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/akagera-national-park-zebras-rwanda.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-3" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Canopy-Walk-Nyungwe-1024x683.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Canopy Nyungwe</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Canopy-Walk-Nyungwe-1024x683.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Activities-in-Nyungwe-Forest-National-Park (1).jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">
                      Nyungwe Activities Park
                    </h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Activities-in-Nyungwe-Forest-National-Park (1).jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/thumb_850_128_oo_nyungwehouse_exterior_accommodation_block_view_1527_master.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">
                      Nyungwe Activities Park
                    </h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/thumb_850_128_oo_nyungwehouse_exterior_accommodation_block_view_1527_master.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Cyinzobe-trail-in-Nyungwe-Forest.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">
                      Nyungwe Activities Park
                    </h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Cyinzobe-trail-in-Nyungwe-Forest.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-4" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/lake-kivu.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Lake Kivu</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a href="img/lake-kivu.jpeg" data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/lake-kivu-shores.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Kivu Lakes & Mountain</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/lake-kivu-shores.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-5" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Mountain Gorilla</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/Mountain-gorilla-lighter-900x600-1.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/960x0.webp"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Kivu Lakes & Mountain</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a href="img/960x0.webp" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/GORILLAS-YT-2.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Kivu Lakes & Mountain</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/GORILLAS-YT-2.jpeg"
                      data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/gorilla.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Gorilla</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a href="img/gorilla.jpeg" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-6" class="tab-pane fade show p-0">
            <div class="row g-4">
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/kigali-city-tour.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">City Tour</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a
                      href="img/kigali-city-tour.jpeg"
                      data-lightbox="destination-5"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="destination-img">
                  <img
                    class="img-fluid rounded w-100"
                    src="img/rwanda_.jpeg"
                    alt=""
                  />
                  <div class="destination-overlay p-4">
                    <h4 class="text-white mb-2 mt-3">Rwanda</h4>
                    <!-- <a href="#" class="btn-hover text-white"
                      >View All Place <i class="fa fa-arrow-right ms-2"></i
                    ></a> -->
                  </div>
                  <div class="search-icon">
                    <a href="img/rwanda_.jpeg" data-lightbox="destination-6"
                      ><i
                        class="fa fa-plus-square fa-1x btn btn-light btn-lg-square text-success"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Destination End -->

  <!-- Explore Tour Start -->
  <div class="container-fluid ExploreTour py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Explore Tour</h5>
        <h1 class="mb-4">Visit Rwanda</h1>
        <p class="mb-0">
          Embark on an unforgettable exploration of Rwanda's diverse landscapes
          with our captivating country tour. pen_spark This comprehensive
          adventure allows you to experience the best of Rwanda, from its
          breathtaking wilderness to its captivating capital.
        </p>
      </div>
      <div class="tab-class text-center">
        <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
          <li class="nav-item">
            <a
              class="d-flex mx-3 py-2 border border-success bg-light rounded-pill active"
              data-bs-toggle="pill"
              href="#NationalTab-1"
            >
              <span class="text-dark" style="width: 250px"
                >National Tour Category</span
              >
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="NationalTab-1" class="tab-pane fade show p-0 active">
            <div class="row g-4">
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/explore-tour-1.jpg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Weekend Tour
                      </h5>
                      <!-- <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a> -->
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/S_176219.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Holiday Tour
                      </h5>
                      <!-- <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a> -->
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/The-course-.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">Road Trip</h5>
                      <!-- <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a> -->
                    </div>
                  </div>
                  <div class="tour-offer bg-info">15% Off</div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/RWANDA-MUSEUMS-2-scaled.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Historical Trip
                      </h5>
                      <!-- <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a> -->
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/Family-Game-Drives-Rwanda.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">
                        Family Tour
                      </h5>
                      <!-- <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a> -->
                    </div>
                  </div>
                  <div class="tour-offer bg-warning">50% Off</div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="national-item">
                  <img
                    src="img/hqdefault.jpeg"
                    class="img-fluid w-100 rounded"
                    alt="Image"
                  />
                  <div class="national-content">
                    <div class="national-info">
                      <h5 class="text-white text-uppercase mb-2">Beach Tour</h5>
                      <!-- <a href="#" class="btn-hover text-white"
                        >View All Place <i class="fa fa-arrow-right ms-2"></i
                      ></a> -->
                    </div>
                  </div>
                  <div class="national-plus-icon">
                    <a href="#" class="my-auto"
                      ><i class="fas fa-link fa-2x text-white"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Explore Tour Start -->

  <!-- Gallery Start -->
  <!-- <div class="container-fluid gallery py-5 my-5">
    <div class="mx-auto text-center mb-5" style="max-width: 900px">
      <h5 class="section-title px-3">Our Gallery</h5>
      <h1 class="mb-4">Visit Gorilla Rwanda & Traveling Gallery.</h1>
      <p class="mb-0">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum tempore
        nam, architecto doloremque velit explicabo? Voluptate sunt eveniet fuga
        eligendi! Expedita laudantium fugiat corrupti eum cum repellat a laborum
        quasi.
      </p>
    </div>
    <div class="tab-class text-center">
      <ul class="nav nav-pills d-inline-flex justify-content-center mb-5">
        <li class="nav-item">
        </li>
      </ul>
      <div class="tab-content">
        <div id="GalleryTab-1" class="tab-pane fade show p-0 active">
          <div class="row g-2">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery1.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Packages <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery1.jpeg"
                    data-lightbox="gallery-1"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery2.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery2.jpeg"
                    data-lightbox="gallery-2"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery3.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery3.jpeg"
                    data-lightbox="gallery-3"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery4.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery4.jpeg"
                    data-lightbox="gallery-4"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery6.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                   <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery6.jpeg"
                    data-lightbox="gallery-5"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery7.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery7.jpeg"
                    data-lightbox="gallery-6"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery9.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery9.jpeg"
                    data-lightbox="gallery-7"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery11.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery11.jpeg"
                    data-lightbox="gallery-8"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery12.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery12.jpeg"
                    data-lightbox="gallery-9"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
              <div class="gallery-item h-100">
                <img
                  src="img/gallery/gallery14.jpeg"
                  class="img-fluid w-100 h-100 rounded"
                  alt="Image"
                />
                <div class="gallery-content">
                  <div class="gallery-info">
                    <h5 class="text-white text-uppercase mb-2">Visit Gorilla Rwanda</h5>
                    <a href="/packages" class="btn-hover text-white"
                      >View Package <i class="fa fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                </div>
                <div class="gallery-plus-icon">
                  <a
                    href="img/gallery/gallery14.jpeg"
                    data-lightbox="gallery-10"
                    class="my-auto"
                    ><i class="fas fa-plus fa-2x text-white"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Gallery End -->

  <!-- Tour Booking Start -->
  <div class="container-fluid booking py-5">
    <div class="container py-5">
      <div class="row g-5 align-items-center">
        <div class="col-lg-6">
          <h5 class="section-booking-title pe-3">Booking</h5>
          <h1 class="text-white mb-4">Online Booking</h1>
          <p class="text-white mb-4">
            A member of our experienced travel specialist team will contact you
            to discuss your travel preferences, dates, and any special requests
            you might have.
          </p>
          <p class="text-white mb-4">
            We can tailor itineraries or recommend alternative tours based on
            your interests and travel style.
          </p>
          <!-- <a
            href="#"
            class="btn btn-light text-success rounded-pill py-3 px-5 mt-2"
            >Read More</a
          > -->
        </div>
        <div class="col-lg-6">
          <h1 class="text-white mb-3">Book A Tour Deals</h1>
          <p class="text-white mb-4">
            Get <span class="text-warning">50% Off</span> On Your First
            Adventure Trip With Visit Gorilla Rwanda. Get More Deal Offers Here.
          </p>
          <form>
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control bg-white border-0"
                    v-model="name"
                    placeholder="Your Name"
                  />
                  <label for="name">Your Name</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input
                    type="email"
                    class="form-control bg-white border-0"
                    v-model="email"
                    placeholder="Your Email"
                  />
                  <label for="email">Your Email</label>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="form-floating date"
                  id="date3"
                  data-target-input="nearest"
                >
                  <input
                    type="date"
                    class="form-control bg-white border-0"
                    v-model="bookingDate"
                    placeholder="Date & Time"
                    data-target="#date3"
                    data-toggle="datetimepicker"
                  />
                  <label for="datetime">Date & Time</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select class="form-select bg-white border-0" id="select1" v-model="destination">
                    <option value="Kigali Tour">Kigali Tour</option>
                    <option value="Country Tour">Country Tour</option>
                    <option value="Akagera National Park">Akagera National Park</option>
                    <option value="Nyungwe National Park">Nyungwe National Park</option>
                    <option value="Gorilla Mountain Park">Gorilla Mountain Park</option>
                  </select>
                  <label for="select1">Destination</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select
                    class="form-select bg-white border-0"
                    id="SelectPerson" v-model="persons"
                  >
                    <option value="1">Person 1</option>
                    <option value="2">Persons 2</option>
                    <option value="3">Persons 3</option>
                    <option value="4">Persons 4</option>
                    <option value="5">Persons 5</option>
                    <option value="6">Persons 6</option>
                    <option value="7">Persons 7</option>
                  </select>
                  <label for="SelectPerson">Persons</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select
                    class="form-select bg-white border-0"
                    id="CategoriesSelect" v-model="kids"
                  >
                    <option value="1">Kids</option>
                    <option value="2">1</option>
                    <option value="3">2</option>
                    <option value="3">3</option>
                  </select>
                  <label for="CategoriesSelect">Categories</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control bg-white border-0"
                    placeholder="Special Request"
                    id="message" v-model="message"
                    style="height: 100px"
                  ></textarea>
                  <label for="message">Special Request</label>
                </div>
              </div>
              <div class="col-12">
                <button
                  class="btn btn-success text-white w-100 py-3"
                  type="button" @click="booking();"
                >
                  Book Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Tour Booking End -->

  <!-- Blog Start -->
  <!-- <div class="container-fluid blog py-5">
    <div class="container py-5">
      <div class="mx-auto text-center mb-5" style="max-width: 900px">
        <h5 class="section-title px-3">Our Blog</h5>
        <h1 class="mb-4">Popular Travel Blogs</h1>
        <p class="mb-0">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
          deserunt tenetur sapiente atque. Magni non explicabo beatae sit, vel
          reiciendis consectetur numquam id similique sunt error obcaecati
          ducimus officia maiores.
        </p>
      </div>
      <div class="row g-4 justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="blog-item">
            <div class="blog-img">
              <div class="blog-img-inner">
                <img
                  class="img-fluid w-100 rounded-top"
                  src="img/blog-1.jpg"
                  alt="Image"
                />
                <div class="blog-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div
                class="blog-info d-flex align-items-center border border-start-0 border-end-0"
              >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-calendar-alt text-success me-2"></i>28 Jan
                  2050</small
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white border-end py-2"
                  ><i class="fa fa-thumbs-up text-success me-2"></i>1.7K</a
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white py-2"
                  ><i class="fa fa-comments text-success me-2"></i>1K</a
                >
              </div>
            </div>
            <div class="blog-content border border-top-0 rounded-bottom p-4">
              <p class="mb-3">Posted By: Royal Hamblin</p>
              <a href="#" class="h4">Adventures Trip</a>
              <p class="my-3">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <a href="#" class="btn btn-success rounded-pill py-2 px-4"
                >Read More</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="blog-item">
            <div class="blog-img">
              <div class="blog-img-inner">
                <img
                  class="img-fluid w-100 rounded-top"
                  src="img/blog-2.jpg"
                  alt="Image"
                />
                <div class="blog-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div
                class="blog-info d-flex align-items-center border border-start-0 border-end-0"
              >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-calendar-alt text-success me-2"></i>28 Jan
                  2050</small
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white border-end py-2"
                  ><i class="fa fa-thumbs-up text-success me-2"></i>1.7K</a
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white py-2"
                  ><i class="fa fa-comments text-success me-2"></i>1K</a
                >
              </div>
            </div>
            <div class="blog-content border border-top-0 rounded-bottom p-4">
              <p class="mb-3">Posted By: Royal Hamblin</p>
              <a href="#" class="h4">Adventures Trip</a>
              <p class="my-3">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <a href="#" class="btn btn-success rounded-pill py-2 px-4"
                >Read More</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="blog-item">
            <div class="blog-img">
              <div class="blog-img-inner">
                <img
                  class="img-fluid w-100 rounded-top"
                  src="img/blog-3.jpg"
                  alt="Image"
                />
                <div class="blog-icon">
                  <a href="#" class="my-auto"
                    ><i class="fas fa-link fa-2x text-white"></i
                  ></a>
                </div>
              </div>
              <div
                class="blog-info d-flex align-items-center border border-start-0 border-end-0"
              >
                <small class="flex-fill text-center border-end py-2"
                  ><i class="fa fa-calendar-alt text-success me-2"></i>28 Jan
                  2050</small
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white border-end py-2"
                  ><i class="fa fa-thumbs-up text-success me-2"></i>1.7K</a
                >
                <a
                  href="#"
                  class="btn-hover flex-fill text-center text-white py-2"
                  ><i class="fa fa-comments text-success me-2"></i>1K</a
                >
              </div>
            </div>
            <div class="blog-content border border-top-0 rounded-bottom p-4">
              <p class="mb-3">Posted By: Royal Hamblin</p>
              <a href="#" class="h4">Adventures Trip</a>
              <p class="my-3">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam eos
              </p>
              <a href="#" class="btn btn-success rounded-pill py-2 px-4"
                >Read More</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Blog End -->

  <!-- Subscribe Start -->
  <div class="container-fluid subscribe py-5">
    <div class="container text-center py-5">
      <div class="mx-auto text-center" style="max-width: 900px">
        <h5 class="subscribe-title px-3">Subscribe</h5>
        <h1 class="text-white mb-4">Our Newsletter</h1>
        <p class="text-white mb-5">
          Subscribe to our blog Newsletter to get our latest news and discount
          tour price.
        </p>
        <div class="position-relative mx-auto">
          <input
            class="form-control border-success rounded-pill w-100 py-3 ps-4 pe-5"
            type="text"
            placeholder="Your email"
          />
          <button
            type="button"
            class="btn btn-success rounded-pill position-absolute top-0 end-0 py-2 px-4 mt-2 me-2"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Subscribe End -->

  <!-- Footer Start -->
  <FooterItem />
  <!-- Footer End -->
</template>
<script>
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    FooterItem
  },
  data () {
    return {
      name: '',
      email: '',
      bookingDate: '',
      destination: '',
      persons: '',
      kids: '',
      message: ''
    }
  },
  methods: {
    booking () {
      const website = 'https://visitgorillarwanda.com/'
      const title = 'Booking Details Information'
      const phoneNumber = '250788590305'
      const name = this.name
      const email = this.email
      const dateRequest = this.bookingDate
      const bookingDestination = this.destination
      const person = this.persons
      const kid = this.kids
      const request = this.message
      const message = `${website},  ${title} \n \n Names: ${name} , \n \n Email:  ${email} \n \n Booking Date: ${dateRequest}, \n \n Destination: ${bookingDestination} \n  Persons: ${person} \n \n Kids: ${kid} \n \n specian request: ${request}`
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
      window.open(whatsappUrl, '_blank')
    }
  }
}
</script>
